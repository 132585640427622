import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Layout } from "antd";

import Header from "./components/Header";
import Footer from "./components/Footer/Footer";
import ErrorPage from "./components/ErrorPage";

import User from "./pages/user";
import Home from "./pages/Home";
import Agent from "./pages/agent";
import AgentLogin from "./pages/agent/AgentLogin";
import MobileVerification from "./pages/user/MobileVerification";

import "./styles/index.scss";

const App = () => {
  return (
    <div>
      <Layout className="layout">
        <BrowserRouter>
          <Header />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/404" component={ErrorPage} />
            
            {/* User Routes */}
            <Route
              exact
              path="/user/mobile-verification"
              component={MobileVerification}
            />
            <Route path="/user/:formPath" component={User} exact />
            {/* Agent Routes */}
            <Route path="/agent/login" component={AgentLogin} exact />
            <Route path="/agent/:formPath" component={Agent} exact />
            <Route
              path="/agent/:formPath/:menu/:subMenu"
              component={Agent}
              exact
            />
            <Route render={() => <Redirect to={{ pathname: "/" }} />} />
          </Switch>
        </BrowserRouter>
        <Footer />
      </Layout>
    </div>
  );
};

export default App;
