import axios from "axios";
import cookies from "js-cookie";

//const base_url = process.env.REACT_APP_BASE_URL;
// const base_url = "https://dev.ekyc.publicdemo.xyz/api/v1/";
// const base_url = "http://127.0.0.1:8000/api/v1/";
const base_url = `https://dev-ekyc.sslwireless.com/api/v1/`;
const hasToken = () => cookies.get("token");
const hasSession = () =>
  localStorage.getItem("User") &&
  JSON.parse(localStorage.getItem("User"))?.client_session_key;

const generateHeaders = () => {
  if (hasToken()) return { authorization: `Bearer ${hasToken()}` };
  else if (hasSession()) return { "session-key": hasSession() };
  else return false;
};

export const getData = async (query, data, no_token) => {
  try {
    let result = await axios({
      method: "GET",
      url: `${base_url}${query}`,
      params: data,
      headers: no_token ? {} : generateHeaders(),
    });
    return result.data;
  } catch (error) {
    if (error?.response?.status === 401) {
      localStorage.removeItem("User");
      window.location = "/";
    }
  }
};

export const postData = async (query, data, no_token) => {
  try {
    let result = await axios({
      method: "POST",
      url: `${base_url}${query}`,
      headers: no_token ? {} : generateHeaders(),
      data: data,
    });
    return result.data;
  } catch (error) {
    if (error?.response?.status === 401) {
      localStorage.removeItem("user");
      window.location = "/";
    }
  }
};

// export const base_image_url = process.env.REACT_APP_BASE_IMAGE_URL;
export const base_image_url = `https://dev-ekyc.sslwireless.com/`;
// export const base_image_url = "https://dev.ekyc.publicdemo.xyz/";
