import React, { useEffect, useState } from "react";
import DefaultNID from "../../../assets/images/nid.png";
import "./BothFormField.scss";
import { Form, Input, Select, Radio, Col, DatePicker } from "antd";
import _ from "lodash";
import moment from "moment";
import {
    Layout,
    Card,
    Typography,
    Dropdown,
    Menu,
} from "antd";
import {
  CameraTwoTone,
} from "@ant-design/icons";
import { scannerConfigs } from "../../../utils/constants";
import {
    dateFormatter,
    dataURItoBlob,
    isSectionSkipable,
    scannerErrorCodeToString,
} from "../../../utils/util-functions";
import { dateFormat } from "../../../utils/constants";
import {useStateIfMounted} from "use-state-if-mounted";


const BothFormField = (props) => {
    // Props
    const {
        field,
        index,
        User,
        name,
        uploadList,
        setUploadList,
        setFileInfoArray,
        fileInfoArray,
        initialValue,
        sectionId,
    } = props;

    // States
    const [selectedImages, setSelectedImages] = useStateIfMounted({});
    const [nidFront, setNidFront] = useState(null);
    const [showCameraSection, setShowCameraSection] = useState(false);
    const [showCamera, setShowCamera] = useState(false);
    const [camIndex, setCamIndex] = useState({});
    const [camImage, setCamImage] = useState(null);

    // Antd Constants
    const { Option } = Select;
    const { Content } = Layout;

    useEffect(() => {
        return(() => {
            setSelectedImages({});
        });
    }, []);
    

    // const SuccessFunc = (result, index, id, field) => {
    //     if (result.ErrorCode === 0) {
    //         if (result != null && result.BMPBase64.length > 0) {
    //             let display = `data:image/png;base64,${result.BMPBase64}`;
    //             let blob = dataURItoBlob(display);
    //             blob.name = `${Math.floor(Date.now() / 1000)}.png`;

    //             setSelectedImages((sli) => ({ ...sli, [index]: display }));

    //             let matchedIndex = _.findIndex(uploadList, { field_id: id });
    //             let tempList = uploadList;
    //             if (matchedIndex !== -1) {
    //                 tempList[matchedIndex].field_id = `${id}`;
    //                 tempList[matchedIndex].field_value = blob;
    //                 tempList[matchedIndex].is_required = !!field.is_required;

    //                 setUploadList(tempList);
    //             } else {
    //                 setUploadList((prevState) => [
    //                     ...prevState,
    //                     {
    //                         field_id: `${id}`,
    //                         field_value: blob,
    //                         is_required: !!field.is_required,
    //                     },
    //                 ]);
    //             }
    //         }
    //     } else {
    //         message.error(
    //             `Error ${result.ErrorCode}: ${scannerErrorCodeToString(
    //                 result.ErrorCode
    //             )}`
    //         );
    //     }
    // };

    // const CallSGIFPGetData = (index, id, field) => {
    //     setSelectedImages((sli) => ({ ...sli, [index]: "" }));

    //     var uri = "https://localhost:8443/SGIFPCapture";
    //     var xmlhttp = new XMLHttpRequest();

    //     xmlhttp.onreadystatechange = async () => {
    //         if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
    //             let fpobject = await JSON.parse(xmlhttp.responseText);
    //             SuccessFunc(fpobject, index, id, field);
    //         } else if (xmlhttp.status === 404) {
    //             message.error(
    //                 `Error ${xmlhttp.status}: Please Check if the device is running`
    //             );
    //         }
    //     };

    //     message.info(`Give your finger print while the device is running`);
    //     let params = `Timeout=${scannerConfigs.timeOut}&Quality=${scannerConfigs.quality}&licstr=${scannerConfigs.secugen_lic}&templateFormat=${scannerConfigs.templateFormat}&imageWSQRate=${scannerConfigs.imageWSQRate}`;
    //     xmlhttp.open("POST", uri, true);
    //     xmlhttp.send(params);
    //     xmlhttp.onerror = () => {
    //         message.error(
    //             `Error ${xmlhttp.statusText}: Please Check if the device is running`
    //         );
    //     };
    // };

    const fileHandler = (event, index, field) => {
        const { id, files, name } = event.target;
        
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setSelectedImages((sli) => ({
                    ...sli,
                    [index]: reader.result,
                }));
                if (name === "nid_image") setNidFront(reader.result);
            }
        };
        if (event.target.files[0] && event.target.id) {
            reader.readAsDataURL(files[0]);

            let matchedIndex = _.findIndex(uploadList, { field_id: id });
            let tempList = [...uploadList];

            if (matchedIndex !== -1) {
                tempList[matchedIndex].field_id = id;
                tempList[matchedIndex].field_value = files[0];
                tempList[matchedIndex].is_required = !!field.is_required;
                tempList[matchedIndex].section_id = sectionId;

                setUploadList(tempList);
            } else {
                tempList.push({
                    field_id: id,
                    field_value: files[0],
                    is_required: !!field.is_required,
                    section_id: sectionId,
                });

                let fileInfoArrayTemp = fileInfoArray ? [...fileInfoArray] : [];
                fileInfoArrayTemp.push({
                    field_id: id,
                    file_name: files[0].name,
                    file_type: files[0].type,
                    field_name: name,
                    section_id: sectionId,
                });
                setFileInfoArray(fileInfoArrayTemp);

                setUploadList(tempList);
            }
        } else return;
    };

    const cameraOn = (index, id, field) => {
        setCamImage(null);
        setSelectedImages((sli) => ({ ...sli, [index]: "" }));
        setShowCameraSection(true);
        setShowCamera(true);
        setCamIndex(
            (prev) => prev,
            (camIndex.index = index),
            (camIndex.id = `${id}`),
            (camIndex.is_required = !!field.is_required)
        );
    };


    const renderInputByType = (field) => {
        if (
            (field.data_type === "text" || field.data_type === "number") &&
            field.field_type === "input"
        ) {
            return (
                <>
                    <Form.Item
                        label={field.label}
                        key={index}
                        name={[name, JSON.stringify(field?.id)]}
                        id={field.id}
                        placeholder={field.placeholder || ""}
                        initialValue={initialValue}
                        rules={[
                            {
                                required: !!field.is_required,
                                message: "This field is required",
                            },
                        ]}
                    >
                        <Input disabled={!!field.is_readonly} />
                    </Form.Item>
                </>
            );
        } else if (!_.isEmpty(field.options) && field.field_type === "select") {
            return (
                <Form.Item
                    label={field.label}
                    id={field.id}
                    key={index}
                    name={[name, JSON.stringify(field?.id)]}
                    initialValue={initialValue}
                    rules={[
                        {
                            required: !!field.is_required,
                            message: "Please select an option",
                        },
                    ]}
                >
                    <Select
                        placeholder={
                            field.placeholder || `Select ${field.label}`
                        }
                    >
                        {field.options?.map((option, index) => (
                            <Option key={index} value={option.value}>
                                {option.value}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            );
        } else if (field.data_type === "file") {
            return (
                <Content>
                    <div className="upload-container">
                        <div className="upload-section">
                            <Card key={index}>
                                <Typography.Title level={5}>
                                    {field.label}{" "}
                                    {!!field.is_required && (
                                        <span style={{ color: "red" }}>*</span>
                                    )}
                                </Typography.Title>
                                <Typography.Text>
                                    {field.file_source === "camera"
                                        ? "Select an image (max 1MB)"
                                        : field.file_source === "fingerprint"
                                        ? "provide your finger-print"
                                        : "Select an image (max 1MB)"}
                                </Typography.Text>
                                <div className="space">
                                    <img
                                        src={
                                            selectedImages[index] || DefaultNID
                                        }
                                        alt=""
                                    />
                                </div>
                                <br />
                                {field.file_source === "camera" ? (
                                    <Dropdown
                                        overlay={
                                            <Menu>
                                                {/* <Menu.Item
                                                    style={{
                                                        textAlign: "center",
                                                    }}
                                                    icon={<CameraTwoTone />}
                                                    key="1"
                                                    onClick={() => {
                                                        cameraOn(
                                                            index,
                                                            field.id,
                                                            field
                                                        );
                                                    }}
                                                >
                                                    Take Photo
                                                </Menu.Item> */}
                                                <Menu.Item
                                                    style={{
                                                        textAlign: "center",
                                                    }}
                                                    key="2"
                                                >
                                                    <Form.Item
                                                        key={index}
                                                        name={[
                                                            name,
                                                            JSON.stringify(field?.id),
                                                        ]}
                                                        id={field.id}
                                                    >
                                                        <input
                                                            type="file"
                                                            name={
                                                                field.field_name
                                                            }
                                                            id={field.id}
                                                            accept="image/*"
                                                            onChange={(e) => {
                                                                fileHandler(
                                                                    e,
                                                                    index,
                                                                    field
                                                                );
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Menu.Item>
                                            </Menu>
                                        }
                                        placement="topCenter"
                                    >
                                        <label className="upload-btn">
                                            Select
                                        </label>
                                    </Dropdown>
                                ) : field.file_source === "fingerprint" ? (
                                    <label
                                        className="upload-btn"
                                        // onClick={() => {
                                        //     CallSGIFPGetData(
                                        //         index,
                                        //         field.id,
                                        //         field
                                        //     );
                                        // }}
                                    >
                                        Start Scan
                                    </label>
                                ) : (
                                        <Form.Item
                                            key={index}
                                            name={[name, JSON.stringify(field?.id)]}
                                            id={field.id}
                                        >
                                    <label className="upload-btn">
                                            <input
                                                type="file"
                                                name={field.field_name}
                                                id={field.id}
                                                accept="image/*"
                                                onChange={(e) => {
                                                    fileHandler(
                                                        e,
                                                        index,
                                                        field
                                                    );
                                                }}
                                            />
                                        Upload
                                    </label>
                                        </Form.Item>
                                )}
                                <br />
                            </Card>
                        </div>
                    </div>
                </Content>
            );
        } else if (
            field.data_type === "text" &&
            field.field_type === "textarea"
        ) {
            return (
                <>
                    <Form.Item
                        label={field.label}
                        key={index}
                        name={[name, JSON.stringify(field?.id)]}
                        id={field.id}
                        initialValue={initialValue}
                        rules={[
                            {
                                required: !!field.is_required,
                                message: "This field is required",
                            },
                        ]}
                    >
                        <Input.TextArea disabled={!!field.is_readonly} />
                    </Form.Item>
                </>
            );
        } else if (
            field.field_type === "input" &&
            field.data_type === "radio"
        ) {
            return (
                <Form.Item
                    label={field.label}
                    key={index}
                    name={[name, JSON.stringify(field?.id)]}
                    id={field.id}
                    initialValue={initialValue}
                    rules={[
                        {
                            required: !!field.is_required,
                            message: "Please select an option",
                        },
                    ]}
                >
                    <Radio.Group>
                        {field.options?.map((option, index) => (
                            <Radio
                                style={{ display: "flex" }}
                                key={index}
                                value={option.key}
                            >
                                {option.value}
                            </Radio>
                        ))}
                    </Radio.Group>
                </Form.Item>
            );
        } else if (field.data_type === "date" && field.field_type === "input") {
            return (
                <Form.Item
                    label={field.label}
                    key={index}
                    name={[name, JSON.stringify(field?.id)]}
                    id={field.id}
                    initialValue={
                        initialValue
                            ? moment(initialValue, dateFormat)
                            : null
                    }
                    rules={[
                        {
                            required: !!field.is_required,
                            message: "Please select an option",
                        },
                    ]}
                >
                    <DatePicker
                        disabled={!!field.is_readonly}
                        format={dateFormat}
                    />
                </Form.Item>
            );
        }
    };

    return (
        <Col key={index} md={8} xs={24}>
            {renderInputByType(field)}
        </Col>
    );
};

export default BothFormField;
