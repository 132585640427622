import React from "react";
import { Switch } from "react-router-dom";
import { ProtectedRoute } from "../../components/Protected";

import AccountTypes from "../AccountTypes";
import Application from "../Application";

const User = () => {
  const routes = [
    { id: 1, path: "/:userType/application-form", comp: Application },
    { id: 2, path: "/:userType/:accountType", comp: AccountTypes },
  ];

  return (
    <Switch>
      {routes &&
        routes.map((route) => {
          return (
            <ProtectedRoute
              exact
              key={route.id}
              path={route.path}
              component={route.comp}
              type="user"
            />
          );
        })}
    </Switch>
  );
};

export default User;
