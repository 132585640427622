import React from "react";
import "./tableStyle.css";

const DepositTable = ({formFieldData}) => {
    return (
        <table className="transactionTable">
            <tr>
                <th className="headline">Particulars</th>
                <th className="headline">Number of Transactions (Monthly)</th>
                <th className="headline">Maximum Size (per transaction)</th>
                <th className="headline">Total Amount (monthly)</th>
            </tr>
            <tr>
                <td><b>Withdrawals</b></td>
                <td>10</td>
                <td>100</td>
                <td>1000</td>
            </tr>
            <tr>
                <td>Cash Withdrawals (Including online/ATM)</td>
                <td>{formFieldData?.cash_withdrl_num_of_trn}</td>
                <td>100</td>
                <td>{formFieldData?.cash_withdrl_size_total_amount}</td>
            </tr>
            <tr>
                <td>Withdrawals Through Transfer/Instruments</td>
                <td>{formFieldData?.withdrl_trnsfer_num_of_trn}</td>
                <td>{formFieldData?.withdrl_trnsfer_size_trn}</td>
                <td>{formFieldData?.withdrl_trnsfer_size_total_amount}</td>
            </tr>
            <tr>
                <td>Withdrawal of Foreign Currency/Remittance</td>
                <td>{formFieldData?.withdrl_foreign_rmtnce_num_of_trn}</td>
                <td>{formFieldData?.withdrl_foreign_rmtnce_size_trn}</td>
                <td>{formFieldData?.withdrl_foreign_rmtnce_size_total_amount}</td>
            </tr>
            <tr>
                <td>Import Payment</td>
                <td>{formFieldData?.import_prcds_num_of_trn}</td>
                <td>{formFieldData?.import_prcds_size_total_amount}</td>
                <td>{formFieldData?.import_prcds_size_transactions}</td>
            </tr>
            <tr>
                <td>Transfer to BO Account or Stock Market</td>
                <td>{formFieldData?.trnsf_bo_stock_num_of_trn}</td>
                <td>{formFieldData?.trnsf_bo_stock_size_trn}</td>
                <td>{formFieldData?.trnsf_bo_stock_size_total_amount}</td>
            </tr>
            <tr>
                <td>Others (specify)........</td>
                <td>{formFieldData?.withdrl_othr_num_of_trn}</td>
                <td>{formFieldData?.withdrl_othr_size_trn}</td>
                <td>{formFieldData?.withdrl_othr_size_total_amount}</td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td><b>Total Withdrawals: </b></td>
                <td>1000</td>
            </tr>
        </table>
    );
};

export default DepositTable;
