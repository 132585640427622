import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Layout, Form, Input, Button, Card, Typography, message } from "antd";
import OtpTimer from "otp-timer";

import { postData } from "../../../utils/api-service";
import { SEND_OTP, VERIFY_OTP } from "../../../utils/api-list";
import otp_img from "../../../assets/icon/send_otp.png";
import "./MobileVerification.scss";

const { Content } = Layout;

const MobileVerification = () => {
  const [otpSent, setOtpSent] = useState(false);
  const [otpCountdown, setOtpCountdown] = useState(0);
  const [mobileNo, setMobileNo] = useState("");
  const [loading, setLoading] = useState();
  const router = useHistory();

  const submit = async (values) => {
    if (!otpSent) {
      setLoading(true);
      if (!_isValidMobile(values.mobile_no)) {
        message.error("Please enter a valid mobile number");
        setLoading(false);
        return;
      } else {
        let mobileData = new FormData();
        mobileData.append("mobile_no", values.mobile_no);

        let response = await postData(SEND_OTP, mobileData, false);
        if (response && response.code === 200) {
          setMobileNo(values.mobile_no);
          setOtpCountdown(58);
          setOtpSent(true);
          message.warning(response.message[0]);
        }
      }
      setLoading(false);
    } else {
      setLoading(true);
      if (values.otp.length !== 6) {
        message.error("The verification code must be 6 digits");
        setLoading(false);
        return;
      }
      let mobileData = new FormData();
      mobileData.append("mobile_no", values.mobile_no);
      mobileData.append("verification_code", values.otp);

      let response = await postData(VERIFY_OTP, mobileData, false);
      if (response && response.code === 200) {
        let User = JSON.parse(localStorage.getItem("User"));
        User.phone_number = values.mobile_no;
        User.fixed.phone_number = values.mobile_no;
        User.client_session_key = response.data.client_session_key;
        User.mobile_verfied = true;
        localStorage.setItem("User", JSON.stringify(User));
        message.success(response.message[0]);
        setLoading(false);
        router.push(`/user/${User.account_type}`);
      } else if (
        response &&
        (response.code === "E002" || (response && response.code === "E003"))
      ) {
        values.otp = "";
        message.error(response.message[0]);
        setLoading(false);
      }
    }
  };

  const resendOTP = async () => {
    if (!_isValidMobile(mobileNo)) {
      message.error("Please enter a valid mobile number");
      return;
    } else {
      let mobileData = new FormData();
      mobileData.append("mobile_no", mobileNo);

      let response = await postData(SEND_OTP, mobileData, false);
      if (response && response.code === 200) {
        setOtpCountdown(58);
        setOtpSent(true);
        message.warning(response.message[0]);
      }
    }
  };

  const _isValidMobile = (mobile) => {
    const pattern = /(^(\+88|0088)?(01){1}[13456789]{1}(\d){8})$/;
    return pattern.test(mobile);
  };

  return (
    <Content>
      <div className="container">
        <Card cover={<img alt="otp" src={otp_img} />}>
          <Typography.Title level={3}>Mobile Verification</Typography.Title>
          <p>Please enter a valid Mobile Number to Verify</p>
          <Form onFinish={submit} name="basic" layout="vertical">
            <Form.Item
              label="Mobile number"
              name="mobile_no"
              rules={[
                {
                  required: true,
                  message: "please enter a valid mobile number",
                },
              ]}
            >
              <Input disabled={otpSent} />
            </Form.Item>
            {otpSent && (
              <React.Fragment>
                <Form.Item
                  label="OTP"
                  name="otp"
                  rules={[
                    {
                      required: true,
                      message: "please enter verification code",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </React.Fragment>
            )}
            {otpSent && otpCountdown > 0 && (
              <OtpTimer
                seconds={otpCountdown}
                text="Enter Verification Code with in "
                ButtonText="Resend Code"
                background={"#1150ff"}
                resend={resendOTP}
              />
            )}
            <br />
            <Form.Item>
              <Button
                loading={loading}
                size="large"
                block
                type="primary"
                htmlType="submit"
              >
                &nbsp;{!otpSent ? `Send OTP` : `Verify`}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </Content>
  );
};

export default MobileVerification;
