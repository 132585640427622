import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Layout, Radio, Button, Typography, Card, Spin, message } from "antd";
import { SettingTwoTone } from "@ant-design/icons";
import _ from "lodash";

import { postData, base_image_url } from "../../utils/api-service";
import { GET_FORM_FIELDS, GENERATE_TRACKING_ID } from "../../utils/api-list";
import dummyIcon from "../../assets/icon/money_4.png";
import "./AccountTypes.scss";

const AccountTypes = (props) => {
  const {
    match: { params },
  } = props;
  const [currentOptions, setCurrentOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [formOptions, setFormOptions] = useState([]);
  const [selectedFormOption, setSelectedFormOption] = useState("");
  const [loader, setLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(true);
  const router = useHistory();

  useEffect(() => {
    initializeState(params.accountType);
  }, []);

  const fetchForms = async (form_id) => {
    setLoader(true);

    let query = `${GET_FORM_FIELDS(form_id, params.userType)}`;

    let data = new FormData();
    data.append("form_id", form_id);
    data.append("platform_type", "web");

    let response = await postData(query, data);

    if (response && response.code === 200) {
      const nomineeDataArray = response?.data?.form_section?.filter((sectionItem) => sectionItem.section_type === "both_form");
      const completeFormData = response?.data?.form_section?.filter((sectionItem) => sectionItem.is_show_on_tab);
      
      localStorage.setItem(
        "complete_form",
        JSON.stringify(completeFormData)
      );
      localStorage.setItem(
        "nominee_data",
        JSON.stringify(nomineeDataArray)
      );
      fetchTrackingId(form_id);
    } else {
      setLoader(false);
      message.error("Something went wrong please try again");
    }
  };

  const fetchTrackingId = async (form_id) => {
    let query = `${GENERATE_TRACKING_ID(params.userType)}`;

    let trackingData = new FormData();
    trackingData.append("form_id", form_id);
    trackingData.append("platform_type", "web");

    let response = await postData(query, trackingData);

    if (response && response.code === 200) {
      let User = JSON.parse(localStorage.getItem("User"));
      User.request_tracking_uid = response.data.request_tracking_uid;
      User.form_id = response.data.form_id;
      localStorage.setItem("User", JSON.stringify(User));
      router.push(`/${params?.userType}/application-form`);
      setLoader(false);
    } else {
      message.error("Something went wrong");
      setLoader(false);
    }
  };

  const initializeState = (accountType) => {
    let options = JSON.parse(localStorage.getItem(`${accountType}`));
    if (_.isEmpty(options)) {
      return (window.location.href = "/");
    }
    setCurrentOptions(options.form_type);
    setPageLoader(false);
  };

  const onChangeRadio = (event) => {
    setSelectedValue(event.target.value);
  };

  const formOptionChange = (event) => {
    setSelectedValue("");
    setSelectedFormOption(event.target.value);
  };

  const next = () => {
    if (_.isEmpty(selectedValue)) {
      message.error("Please select an Account Type");
      return;
    }
    if (params.userType === "user") {
      if (_.find(selectedValue.form, ["kyc_type", "simplified"])) {
        let form_id = _.find(selectedValue.form, ["kyc_type", "simplified"]).id;
        fetchForms(form_id);
      } else {
        message.error("No form avalible for this Account Type");
      }
    } else if (params.userType === "agent") {
      if (!_.isEmpty(selectedValue.form)) {
        setFormOptions(selectedValue.form);
      } else {
        message.error("No form avalible for this Account Type");
      }
    }
  };

  const agentNext = () => {
    if (_.isEmpty(selectedFormOption)) {
      message.error("Please select a Form Type");
      return;
    }
    fetchForms(selectedFormOption.id);
  };

  const clearFormType = () => {
    setFormOptions([]);
  };

  return (
    <Layout.Content>
      {pageLoader ? (
        <div className="acc-types-container">
          <Spin
            indicator={<SettingTwoTone style={{ fontSize: 50 }} spin />}
            style={{ marginTop: "150px" }}
            tip="Loading...."
          />
        </div>
      ) : (
        <div className="acc-types-container">
          <br />
          <br />
          {_.isEmpty(formOptions) ? (
            <Card>
              <Typography.Title level={3}>
                Select an Account Type
              </Typography.Title>
              <br />
              <Radio.Group
                onChange={(e) => onChangeRadio(e)}
                defaultValue="a"
                buttonStyle="solid"
              >
                {currentOptions &&
                  currentOptions.map((opt, index) => {
                    return (
                      <Radio.Button value={opt} key={index}>
                        <img
                          src={
                            opt.icon
                              ? `${base_image_url}${opt.icon}`
                              : dummyIcon
                          }
                          alt=""
                        />
                        <Typography.Text>{opt.title}</Typography.Text>
                      </Radio.Button>
                    );
                  })}
              </Radio.Group>
              <br />
              <Button
                size="large"
                block
                type="primary"
                loading={loader}
                style={{ marginTop: "20px" }}
                onClick={next}
              >
                &nbsp;Next Step
              </Button>
            </Card>
          ) : (
            <Card>
              <Typography.Title level={3}>Select Form Type</Typography.Title>
              <br />
              <Radio.Group
                onChange={(e) => formOptionChange(e)}
                defaultValue="a"
                buttonStyle="solid"
              >
                {formOptions &&
                  formOptions.map((opt, index) => {
                    return (
                      <Radio.Button value={opt} key={index}>
                        <img
                          src={
                            formOptions.icon
                              ? `${base_image_url}${formOptions.icon}`
                              : dummyIcon
                          }
                          alt=""
                        />
                        <Typography.Text>
                          {opt.kyc_type === "simplified"
                            ? "Simplified Form"
                            : "Regular Form"}
                        </Typography.Text>
                      </Radio.Button>
                    );
                  })}
              </Radio.Group>
              <br />
              <Button
                size="large"
                block
                type="primary"
                loading={loader}
                style={{ marginTop: "20px" }}
                onClick={agentNext}
              >
                &nbsp;Next Step
              </Button>
              <Button
                size="large"
                block
                type="Secondary"
                style={{ marginTop: "20px" }}
                onClick={clearFormType}
              >
                Back
              </Button>
            </Card>
          )}
        </div>
      )}
    </Layout.Content>
  );
};

export default AccountTypes;
