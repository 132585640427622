import React, { useEffect, useState } from "react";
import {
  Layout,
  Form,
  Button,
  Typography,
  message,
  Row,
  Col,
  Card,
} from "antd";
import _ from "lodash";
import Steps from "../../Steps";
import { postData } from "../../../utils/api-service";
import { SUBMIT_FORM_SECTION, UPLOAD_DOCUMENTS } from "../../../utils/api-list";
import { generateKey } from '../../../utils/util-functions';
import "./BothForm.scss";
import BothFormField from "../BothFormField";
import moment from "moment";


function BothForm(props) {
  // Props
  const { formSection, userType, nextForm, prevForm, sequence } = props;

  // States
  const [loader, setLoader] = useState(false);
  const [User] = useState(JSON.parse(localStorage.getItem("User")));
  const [uploadList, setUploadList] = useState([]);
  const [fileInfoArray, setFileInfoArray] = useState(null);
  const [nomineeData] = useState(JSON.parse(localStorage.getItem("nominee_data")));
  const [defaultNominee, setDefaultNominee] = useState(null);
  const [extraNominee, setExtraNominee] = useState(null);
  const [allNominee, setAllNominee] = useState(null);
  const [showMoreNomineeCount, setShowMoreNomineeCount] = useState(0);
  const [nomineeCachedValue] = useState(JSON.parse(localStorage.getItem("nominee_cache_value")));

  // Antd Contstants
  const { Content } = Layout;
  const [nomineeForm] = Form.useForm();

  useEffect(() => {
    if(nomineeData) {
      const defaultNomineeData = nomineeData?.filter((nomineeItem) => nomineeItem?.is_show_on_tab);
      const extraNomineeData = nomineeData?.filter((nomineeItem) => !nomineeItem?.is_show_on_tab);
      const allNomineeData = defaultNomineeData && extraNomineeData && [...defaultNomineeData, ...extraNomineeData];
      
      // defaultNomineeData is data of nominee 01
      // extraNomineeData are data of nominee 02 to last
      defaultNomineeData && setDefaultNominee(defaultNomineeData);
      extraNomineeData && setExtraNominee(extraNomineeData);
      allNomineeData && setAllNominee(allNomineeData);
    }

    return(() => {
      setDefaultNominee(null);
      setExtraNominee(null);
      setAllNominee(null);
    });
  }, [nomineeData]);


  const onFinished = async (values) => {
    console.log('onsubmit values: ', values);

    const inputValuesObject = {...values?.input, ...values?.select} || null;
    const customNomineeObject = {};

    // Change nominee object data structure
    // eslint-disable-next-line no-unused-expressions
    allNominee?.forEach((nomineeItem, index) => {
      let nomineeName = `nominee_id_${nomineeItem?.id}`;
      customNomineeObject[nomineeName] = {};
      // eslint-disable-next-line no-unused-expressions
      nomineeItem?.form_section_fields?.forEach((formItem, formIndex) => {
        if(inputValuesObject[formItem?.id]) {
          customNomineeObject[nomineeName][formItem?.id] = inputValuesObject[formItem?.id];
        }
      });
    });

    let nomineesValuesArray = [];

    if (customNomineeObject) {
        Object.entries(customNomineeObject).forEach(([key, value]) => {
          nomineesValuesArray.push(value);
        });
    }

    if(nomineesValuesArray.length <= 0) {
      setLoader(false);
      message.error(`Data not found!`);
      return null;
    }

    setLoader(true);
    if (!_.isEmpty(uploadList)) {
      let upload_complete = await fileUpload();
      if (upload_complete) sectionSubmission(nomineesValuesArray);
      else return;
    } else sectionSubmission(nomineesValuesArray);
  };

  const sectionSubmission = async (values) => {
    let requestBody = {
      form_id: formSection?.form_id,
      form_section: [],
      request_tracking_uid: User?.request_tracking_uid,
    };


    let result = values?.forEach((nomieeValueArray, index) => {
      let section_field = [];
      _.mapKeys(nomieeValueArray, (value, key) => {
        const foundObject = fileInfoArray && fileInfoArray.find((fileInfo) => fileInfo.field_id === key);
        if(foundObject) {
          // If type is file/image then push data with different structure
          const field_id = foundObject?.field_id || '';
          const field_name = foundObject?.file_name || '';
          const trimmedFieldName = field_name.replace(/\s/g, '');
          const todayDate = moment().format('YYYY-MM-DD');
          const generated_file_name = `upload/user_documents/${todayDate}/${trimmedFieldName}`;
          
          section_field.push({
            field_id: field_id ? parseInt(field_id) : null,
            field_value: generated_file_name || null,
          });
        }
        else {
          section_field.push({
            field_id: parseInt(key),
            field_value: value,
          });
        }
      });
      if (Array.isArray(section_field) && section_field.length > 1) {
          requestBody.form_section.push({
              section_field: section_field,
              section_id: allNominee[index]?.id,
          });
      } 
    });

    let query = SUBMIT_FORM_SECTION(userType);

    let response = await postData(query, requestBody);

    if (response && response.code === 200) {
      let nomineeResponseValue = {};
      _.mapKeys(values, (value, key) => {
        nomineeResponseValue[`${key}`] = value;
      });
      localStorage.setItem("nominee_cache_value", JSON.stringify(nomineeResponseValue));
      message.success(response.message[0]);
      setLoader(false);
      setUploadList([]);
      nextForm();
    } else {
      message.error(response.message[0]);
      setLoader(false);
    }
  };

  const fileUpload = async () => {

    let uploadData = new FormData();
    uploadData.append("form_id", formSection.form_id);
    uploadData.append("request_tracking_uid", User.request_tracking_uid);
    if (!!formSection.should_validated) {
      uploadData.append("verification_type", formSection.verification_type);
    }

    uploadList.forEach((item, index) => {
      uploadData.append(`form[${index}][section_id]`, item.section_id);
      uploadData.append(`form[${index}][field_id]`, item.field_id);
      uploadData.append(`form[${index}][field_value]`, item.field_value);
    });

    let query = UPLOAD_DOCUMENTS(userType);
    let uploadResponse = await postData(query, uploadData);
    if (uploadResponse && uploadResponse.code === 200) {
      message.success(uploadResponse.message[0]);
      return true;
    } else {
      message.error("something went wrong please try again");
      return false;
    }
  };

  const addNominee = () => {
    setShowMoreNomineeCount(showMoreNomineeCount+1);
  }

  return (
      <Content>
          <div className="personal-info-container">
              <Steps currentPage={sequence} />
              <Typography.Title style={{ maxWidth: "440px" }} level={3}>
                  {formSection.name}
              </Typography.Title>
              <Card>
                  <Form
                      name="validate_other"
                      layout="vertical"
                      colon={false}
                      form={nomineeForm}
                      onFinish={onFinished}
                  >
                      <Row gutter={16}>
                          {defaultNominee &&
                              defaultNominee[0]?.form_section_fields?.map(
                                  (field, index) => {
                                      return (
                                          <BothFormField
                                              field={field}
                                              index={index}
                                              User={User}
                                              nomineeCachedValue={nomineeCachedValue}
                                              name={field?.field_type}
                                              uploadList={uploadList}
                                              setUploadList={setUploadList}
                                              setFileInfoArray={
                                                  setFileInfoArray
                                              }
                                              fileInfoArray={fileInfoArray}
                                              key={index}
                                              initialValue={nomineeCachedValue?.[0]?.[field?.id] || null}
                                              sectionId={allNominee?.[0]?.id || null}
                                          />
                                      );
                                  }
                              )}
                      </Row>
                      {extraNominee
                          ?.slice(0, showMoreNomineeCount)
                          ?.map((nomineeItem, nomineeIndex) => {
                              return (
                                  <div style={{ paddingTop: "40px" }} key={generateKey()}>
                                      <Row gutter={16} justify="center">
                                          <Typography.Title
                                              style={{ maxWidth: "340px" }}
                                              level={3}
                                          >
                                              {nomineeItem?.name}
                                          </Typography.Title>
                                      </Row>
                                      <Row gutter={16}>
                                          {nomineeItem?.form_section_fields?.map(
                                              (field, fieldIndex) => {
                                                  return (
                                                      <BothFormField
                                                          field={field}
                                                          index={fieldIndex+1}
                                                          User={User}
                                                          nomineeCachedValue={nomineeCachedValue}
                                                          name={
                                                              field?.field_type
                                                          }
                                                          uploadList={
                                                              uploadList
                                                          }
                                                          setUploadList={
                                                              setUploadList
                                                          }
                                                          setFileInfoArray={
                                                              setFileInfoArray
                                                          }
                                                          fileInfoArray={
                                                              fileInfoArray
                                                          }
                                                          key={fieldIndex}
                                                          initialValue={nomineeCachedValue?.[nomineeIndex+1]?.[field?.id] || null}
                                                          sectionId={allNominee?.[nomineeIndex+1]?.id}
                                                      />
                                                  );
                                              }
                                          )}
                                      </Row>
                                  </div>
                              );
                      })}
                      {showMoreNomineeCount < extraNominee?.length ? (
                          <Row
                              gutter={16}
                              justify="center"
                              style={{
                                  paddingTop: "20px",
                                  paddingBottom: "20px",
                              }}
                          >
                              <Col xs={24}>
                                  <Button
                                      size="middle"
                                      block
                                      type="dashed"
                                      onClick={() => addNominee()}
                                  >
                                      + Add More Nominee
                                  </Button>
                              </Col>
                          </Row>
                      ) : null}

                      <Form.Item>
                          <Row>
                              <Col xs={6}>
                                  <Button
                                      size="large"
                                      block
                                      type="secondary"
                                      onClick={() => prevForm()}
                                  >
                                      Back
                                  </Button>
                              </Col>
                              <Col
                                  md={{ span: 6, offset: 12 }}
                                  xs={{ span: 8, offset: 10 }}
                              >
                                  <Button
                                      size="large"
                                      loading={loader}
                                      block
                                      type="primary"
                                      htmlType="submit"
                                  >
                                      &nbsp;Next Step
                                  </Button>
                              </Col>
                          </Row>
                      </Form.Item>
                  </Form>
              </Card>
          </div>
      </Content>
  );
}

export default BothForm;
