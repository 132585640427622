import React, { useState } from "react";
import {
  Layout,
  Form,
  Button,
  Typography,
  message,
  Row,
  Col,
  Card,
} from "antd";
import _ from "lodash";

import Steps from "../../../components/Steps";
import FormField from "../FormField";
import { postData } from "../../../utils/api-service";
import { SUBMIT_FORM_SECTION, UPLOAD_DOCUMENTS } from "../../../utils/api-list";
import { dateFormat } from "../../../utils/constants";
import "./BasicForm.scss";

const { Content } = Layout;

function BasicForm({ formSection, userType, nextForm, prevForm, sequence }) {
  const [loader, setLoader] = useState(false);
  const [User] = useState(JSON.parse(localStorage.getItem("User")));
  const [uploadList, setUploadList] = useState([]);

  console.log('formSection', formSection);

  const onFinished = async (values) => {
    setLoader(true);
    if (!_.isEmpty(uploadList)) {
      let upload_complete = await fileUpload();
      if (upload_complete) sectionSubmission(values);
      else return;
    } else sectionSubmission(values);
  };

  const sectionSubmission = async (values) => {
    let requestBody = {
      form_section: [],
    };
    let section_field_data = [];

    _.mapKeys(values, (value, key) => {
      if (
        _.find(formSection.form_section_fields, { field_name: key })
          .data_type !== "file"
      ) {
        if (
          _.find(formSection.form_section_fields, { field_name: key })
            .data_type === "date"
        ) {
          section_field_data.push({
            field_id: _.find(formSection.form_section_fields, {
              field_name: key,
            }).id,
            field_value: value ? value.format(dateFormat) : null,
          });
        } else {
          section_field_data.push({
            field_id: _.find(formSection.form_section_fields, {
              field_name: key,
            }).id,
            field_value: value,
          });
        }
      }
    });
    requestBody.form_id = formSection.form_id;
    requestBody.request_tracking_uid = User.request_tracking_uid;
    requestBody.form_section.push({
      section_id: formSection.id,
      section_field: section_field_data,
    });

    let query = SUBMIT_FORM_SECTION(userType);

    let response = await postData(query, requestBody);

    if (response && response.code === 200) {
      _.mapKeys(values, (value, key) => {
        User[`${key}`] = value;
      });
      localStorage.setItem("User", JSON.stringify(User));
      message.success(response.message[0]);
      setLoader(false);
      setUploadList([]);
      nextForm();
    } else {
      message.error(response.message[0]);
      setLoader(false);
    }
  };

  const fileUpload = async () => {
    let uploadData = new FormData();
    uploadData.append("form_id", formSection.form_id);
    uploadData.append("request_tracking_uid", User.request_tracking_uid);
    if (!!formSection.should_validated) {
      uploadData.append("verification_type", formSection.verification_type);
    }
    uploadList.forEach((item, index) => {
      uploadData.append(`form[${index}][section_id]`, formSection.id);
      uploadData.append(`form[${index}][field_id]`, item.field_id);
      uploadData.append(`form[${index}][field_value]`, item.field_value);
    });

    let query = UPLOAD_DOCUMENTS(userType);
    let uploadResponse = await postData(query, uploadData);
    if (uploadResponse && uploadResponse.code === 200) {
      message.success(uploadResponse.message[0]);
      return true;
    } else {
      message.error("something went wrong please try again");
      return false;
    }
  };

  const fileHandle = async (event) => {
    if (event.target.files[0])
      setUploadList((prevState) => [
        ...prevState,
        {
          field_id: event.target.id,
          field_value: event.target.files[0],
        },
      ]);
    else message.error("Please select a valid file");
  };

  return (
    <Content>
      <div className="personal-info-container">
        <Steps currentPage={sequence} />
        <Typography.Title style={{ maxWidth: "440px" }} level={3}>
          {formSection.name}
        </Typography.Title>
        <Card>
          <Form
            name="validate_other"
            layout="vertical"
            colon={false}
            onFinish={onFinished}
          >
            <Row gutter={16}>
              {formSection &&
                formSection.form_section_fields &&
                formSection.form_section_fields.map((field, index) => {
                  return (
                    <FormField
                      formSection={formSection}
                      field={field}
                      fileHandle={fileHandle}
                      key={index}
                      index
                      User={User}
                    />
                  );
                })}
            </Row>
            <Form.Item>
              <Row>
                <Col xs={6}>
                  <Button
                    size="large"
                    block
                    type="secondary"
                    onClick={() => prevForm()}
                  >
                    Back
                  </Button>
                </Col>
                <Col md={{ span: 6, offset: 12 }} xs={{ span: 8, offset: 10 }}>
                  <Button
                    size="large"
                    loading={loader}
                    block
                    type="primary"
                    htmlType="submit"
                  >
                    &nbsp;Next Step
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </Content>
  );
}

export default BasicForm;
