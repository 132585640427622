import React from "react";
import { Redirect, Route } from "react-router-dom";
import cookies from "js-cookie";

export const ProtectedRoute = (props) => {
  const isAuthentic = () => {
    if (cookies.get("token") && cookies.get("agent")) return true;
    else return false;
  };

  const hasSession = () => {
    if (
      localStorage.getItem("User") &&
      JSON.parse(localStorage.getItem("User"))?.client_session_key
    )
      return true;
    else return false;
  };

  return (
    <Route
      exact
      path={props.path}
      render={(data) => {
        if (props.type === "user") {
          if (hasSession()) return <props.component {...data} />;
          else return <Redirect to={{ pathname: "/" }} />;
        } else if (props.type === "agent") {
          if (isAuthentic()) return <props.component {...data} />;
          else return <Redirect to={{ pathname: "/404" }} />;
        } else return <Redirect to={{ pathname: "/" }} />;
      }}
    />
  );
};
