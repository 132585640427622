import React from "react";
import { Form, Input, Select, Radio, Col, DatePicker } from "antd";
import _ from "lodash";
import moment from "moment";

import { dateFormat } from "../../../utils/constants";
import "./FormField.scss";

const { Option } = Select;

const FormField = ({ field, index, User, fileHandle }) => {
  const renderInputByType = (field) => {
    if (
      (field.data_type === "text" || field.data_type === "number") &&
      field.field_type === "input"
    ) {
      return (
        <Form.Item
          label={field.label}
          key={index}
          name={field.field_name}
          id={field.id}
          placeholder={field.placeholder || ""}
          initialValue={User[`${field.field_name}`]}
          rules={[
            {
              required: !!field.is_required,
              message: "This field is required",
            },
          ]}
        >
          <Input disabled={!!field.is_readonly} />
        </Form.Item>
      );
    } else if (!_.isEmpty(field.options) && field.field_type === "select") {
      return (
        <Form.Item
          label={field.label}
          id={field.id}
          key={index}
          name={field.field_name}
          initialValue={User[`${field.field_name}`]}
          rules={[
            {
              required: !!field.is_required,
              message: "Please select an option",
            },
          ]}
        >
          <Select placeholder={field.placeholder || `Select ${field.label}`}>
            {field.options?.map((option, index) => (
              <Option key={index} value={option.value}>
                {option.value}
              </Option>
            ))}
          </Select>
        </Form.Item>
      );
    } else if (field.data_type === "file") {
      return (
        <Form.Item
          label={field.label}
          id={field.id}
          key={index}
          name={field.field_name}
          rules={[
            {
              required: !!field.is_required,
              message: "Please Upload a valid file",
            },
          ]}
        >
          <input
            type="file"
            name={field.field_name}
            id={field.id}
            onChange={fileHandle}
          />
        </Form.Item>
      );
    } else if (field.data_type === "text" && field.field_type === "textarea") {
      return (
        <Form.Item
          label={field.label}
          key={index}
          name={field.field_name}
          id={field.id}
          initialValue={User[`${field.field_name}`]}
          rules={[
            {
              required: !!field.is_required,
              message: "This field is required",
            },
          ]}
        >
          <Input.TextArea disabled={!!field.is_readonly} />
        </Form.Item>
      );
    } else if (field.field_type === "input" && field.data_type === "radio") {
      return (
        <Form.Item
          label={field.label}
          key={index}
          name={field.field_name}
          id={field.id}
          initialValue={User[`${field.field_name}`]}
          rules={[
            {
              required: !!field.is_required,
              message: "Please select an option",
            },
          ]}
        >
          <Radio.Group>
            {field.options?.map((option, index) => (
              <Radio style={{ display: "flex" }} key={index} value={option.key}>
                {option.value}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      );
    } else if (field.data_type === "date" && field.field_type === "input") {
      return (
        <Form.Item
          label={field.label}
          key={index}
          name={field.field_name}
          id={field.id}
          initialValue={
            User[`${field.field_name}`]
              ? moment(User[`${field.field_name}`], dateFormat)
              : null
          }
          rules={[
            {
              required: !!field.is_required,
              message: "Please select an option",
            },
          ]}
        >
          <DatePicker disabled={!!field.is_readonly} format={dateFormat} />
        </Form.Item>
      );
    }
  };

  return (
    <Col key={index} md={8} xs={24}>
      {renderInputByType(field)}
    </Col>
  );
};

export default FormField;
