import _ from "lodash";
import { v4 as uuidv4 } from 'uuid';

export const dateFormatter = (date) => {
  let shortenedDate = date.substring(0, 13);
  let d = new Date(shortenedDate),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const dataURItoBlob = (dataURI) => {
  let byteString = atob(dataURI.split(",")[1]);
  let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  let ab = new ArrayBuffer(byteString.length);
  let ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
};

export const isSectionSkipable = (formSection) => {
  let requiredFields = [];
  _.map(formSection.form_section_fields, (field) => {
    if (!!field.is_required) {
      requiredFields.push(`${field.id}`);
    }
  });

  if (_.isEmpty(requiredFields)) return true;
  else return false;
};

export const scannerErrorCodeToString = (ErrorCode) => {
  let Description;
  switch (ErrorCode) {
    case 51:
      Description = "System file load failure";
      break;
    case 52:
      Description = "Sensor chip initialization failed";
      break;
    case 53:
      Description = "Device not found";
      break;
    case 54:
      Description = "Fingerprint image capture timeout";
      break;
    case 55:
      Description = "No device available";
      break;
    case 56:
      Description = "Driver load failed";
      break;
    case 57:
      Description = "Wrong Image";
      break;
    case 58:
      Description = "Lack of bandwidth";
      break;
    case 59:
      Description = "Device Busy";
      break;
    case 60:
      Description = "Cannot get serial number of the device";
      break;
    case 61:
      Description = "Unsupported device";
      break;
    case 63:
      Description = "SgiBioSrv didn't start; Try image capture again";
      break;
    default:
      Description =
        "Unknown error code or Update code to reflect latest result";
      break;
  }
  return Description;
};

export const getCurrentTime = () => {
  return Math.floor(Date.now() / 1000);
};

export const generateKey = () => {
  return uuidv4();
}

export const getNIDScore = (document_resemblance_score, object_detection_score) => {
  if(document_resemblance_score > 40) {
    return document_resemblance_score;
  }
  else {
    return object_detection_score;
  }
}
