import { Button, Card, Input, Layout, Form, Typography, Divider } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import cookies from "js-cookie";

import { AGENT_LOGIN, AGENT_PROFILE } from "../../../utils/api-list";
import { postData, getData } from "../../../utils/api-service";
import loginIcon from "../../../assets/icon/agent_login.svg";
import "./agent-login.scss";

const AgentLogin = () => {
  const router = useHistory();
  const [loading, setLoading] = useState();

  const submit = async (values) => {
    setLoading(true);
    const res = await postData(AGENT_LOGIN, values);
    if (res?.code === 200) {
      cookies.set("token", res?.data?.access_token);
      cookies.set("agent", {});
      const agent = await getData(AGENT_PROFILE);
      if (agent?.code === 200) {
        cookies.set("agent", JSON.stringify(agent.data));
      }
      setLoading(false);
      router.push("/");
    } else setLoading(false);
  };

  return (
    <Layout.Content>
      <div className="agent-login-container">
        <Card>
          <div className="login-icon">
            <img src={loginIcon} alt="login_icon" />
          </div>
          <Typography.Title style={{ textAlign: "center" }} level={4}>
            Agent Login
          </Typography.Title>
          <Divider />
          <Form layout="vertical" name="login" onFinish={submit}>
            <Form.Item label="Email" name="email">
              <Input type="email" placeholder="example@gmail.com" />
            </Form.Item>
            <Form.Item label="Password" name="password">
              <Input type="password" placeholder="Password" />
            </Form.Item>
            <Form.Item>
              <Button
                size="large"
                block
                loading={loading}
                type="primary"
                htmlType="submit"
              >
                Login
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </Layout.Content>
  );
};

export default AgentLogin;
