import React, { useState, useEffect } from "react";
import { Spin, Layout } from "antd";
import { SettingTwoTone } from "@ant-design/icons";
import _ from "lodash";
import UploadForm from "../../components/FormComponents/UploadForm";
import BasicForm from "../../components/FormComponents/BasicForm";
import BothForm from "../../components/FormComponents/BothForm";
import Confirmation from "../../components/FormComponents/Confirmation";

const Application = (props) => {
  const {match: { params },} = props;
  const [complete_form, setComplete_form] = useState(
    JSON.parse(localStorage.getItem("complete_form"))
  );
  const [selectedForm, setSelectedForm] = useState({});
  const [loader, setLoader] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [userType, setuserType] = useState(null);

  console.log('complete_form', complete_form);

  useEffect(() => {
    initializeState();
  }, []);

  const initializeState = () => {
    setComplete_form([
      ...complete_form,
      {
        name: "Confirmation",
        sequence: complete_form.length + 1,
        id: `${complete_form.length + 1}conf`,
      },
    ]);
    setSelectedForm(complete_form[0]);
    setCurrentIndex(0);
    setLoader(false);
    setuserType(params.userType);
  };

  const renderFormSection = (selectedForm) => {
    if (selectedForm.section_type === "uploader_form") {
      return (
        <UploadForm
          nextForm={nextForm}
          prevForm={prevForm}
          userType={userType}
          sequence={selectedForm.sequence - 1}
          formSection={selectedForm}
        />
      );
    } else if (selectedForm.section_type === "basic_form") {
      return (
        <BasicForm
          nextForm={nextForm}
          prevForm={prevForm}
          sequence={selectedForm.sequence - 1}
          userType={userType}
          formSection={selectedForm}
        />
      );
    } else if (selectedForm.section_type === "both_form") {
      return (
        <>
          <BothForm
            nextForm={nextForm}
            prevForm={prevForm}
            sequence={selectedForm.sequence - 1}
            userType={userType}
            formSection={selectedForm}
          />
        </>
      );
    } else if (selectedForm.name === "Confirmation") {
      return (
        <Confirmation
          prevForm={prevForm}
          sequence={selectedForm.sequence - 1}
          userType={userType}
        />
      );
    }
  };

  const nextForm = () => {
    let runningIndex = _.findIndex(complete_form, { id: selectedForm.id });
    if (runningIndex < complete_form.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setSelectedForm(complete_form[currentIndex + 1]);
    } else return;
  };

  const prevForm = () => {
    let runningIndex = _.findIndex(complete_form, { id: selectedForm.id });
    if (runningIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setSelectedForm(complete_form[currentIndex - 1]);
    } else return;
  };

  if (_.isEmpty(complete_form)) {
    return (window.location.href = "/");
  }

  return loader ? (
    <Layout.Content>
      <div className="home-container">
        <Spin
          indicator={<SettingTwoTone style={{ fontSize: 50 }} spin />}
          style={{ marginTop: "150px" }}
          tip="Loading...."
        />
      </div>
    </Layout.Content>
  ) : (
    <Layout.Content>
      <div>{renderFormSection(selectedForm)}</div>
    </Layout.Content>
  );
};

export default Application;
