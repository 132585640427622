import React from "react";
import "./tableStyle.css";

const DepositTable = ({formFieldData}) => {
    return (
        <table className="transactionTable">
            <tr>
                <th className="headline">Particulars</th>
                <th className="headline">Number of Transactions (Monthly)</th>
                <th className="headline">Maximum Size (per transaction)</th>
                <th className="headline">Total Amount (monthly)</th>
            </tr>
            <tr>
                <td><b>Deposits</b></td>
                <td>10</td>
                <td>100</td>
                <td>1000</td>
            </tr>
            <tr>
                <td>Cash Deposit (Including Online)</td>
                <td>{formFieldData?.cash_deposit_num_of_trn}</td>
                <td>{formFieldData?.cash_deposit_size_trn}</td>
                <td>{formFieldData?.cash_deposit_size_total_amount}</td>
            </tr>
            <tr>
                <td>Deposit Through Transfer/Instruments</td>
                <td>{formFieldData?.deposit_trnsfer_num_of_trn}</td>
                <td>{formFieldData?.deposit_trnsfer_size_trn}</td>
                <td>{formFieldData?.deposit_trnsfer_size_total_amount}</td>
            </tr>
            <tr>
                <td>Foreign Remittance Deposit (inward)</td>
                <td>{formFieldData?.foreign_rmtnce_num_of_trn}</td>
                <td>{formFieldData?.foreign_rmtnce_size_trn}</td>
                <td>{formFieldData?.foreign_rmtnce_size_total_amount}</td>
            </tr>
            <tr>
                <td>Export Proceeds</td>
                <td>{formFieldData?.export_prcds_num_of_trn}</td>
                <td>{formFieldData?.export_prcds_size_transactions}</td>
                <td>{formFieldData?.export_prcds_size_total_amount}</td>
            </tr>
            <tr>
                <td>From BO or Stock Market</td>
                <td>{formFieldData?.bo_stock_num_of_trn}</td>
                <td>{formFieldData?.bo_stock_size_trn}</td>
                <td>{formFieldData?.bo_stock_size_total_amount}</td>
            </tr>
            <tr>
                <td>Others (specify)........</td>
                <td>{formFieldData?.othr_num_of_trn}</td>
                <td>{formFieldData?.othr_size_trn}</td>
                <td>{formFieldData?.othr_size_total_amount}</td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td><b>Total Deposits: </b></td>
                <td>1000</td>
            </tr>
        </table>
    );
};

export default DepositTable;
